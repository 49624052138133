*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  --darkpurple: #240130;
  --greylight: #E9EBED;

  --white: #ffffff;
  --black: #000000;

  --lightwhite: #CFCFD0;
  --extralight: #DADADA;

  --outlinecolor: #B87FFE;
  --ratingcolor: #7E57C2;

  --helpiconcolor: #b6b6b6;
  --hovercolor: #4e2a8d;

  --mat: #e5e5e5;

}

/* html {
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
} */

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  background-color: var(--greylight);
}

.loading-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.warning-head {
  padding: 0px;
  margin: 0px;
  color: #dd646f;
  font-size: 24px;
}

.warning-subhead {
  font-size: 17px;
}

.warning-btn-container {
  padding: 30px 0px !important;
}

.warning-btn {
  padding: 10px 26px !important;
}

.btlr4 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bblr4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p4-8 {
  padding: 4px 8px !important;
}

/* Common Css */
.ck.ck-editor__editable_inline {
  border: 1px solid var(--helpiconcolor) !important;
  position: relative;
  padding: 24px 16px !important;
}

.ck.ck-editor__editable_inline:hover {
  border: 1px solid var(--outlinecolor) !important;
  position: relative;
  /* padding: 24px 16px; */
}

/* .ck.ck-editor__editable_inline img {
  max-width: 200px;
  max-height: 200px;
  display: inline-flex;
} */

#sidebar a {
  color:var(--white);
  text-decoration: none;
}

.sidebar_wrap {
  display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}



table {
  border-collapse: collapse;
}


.tr-qa-card {
  padding: 32px 32px 16px 32px;
  border-bottom: 1px solid var(--lightwhite);
  background: #fff;
  box-shadow: 0px 0px 23px 0px rgb(0 0 0 / 3%);
}

.tr-qa-card-pty {
  padding: 0px 32px !important;
}

.tr-answer {
  padding: 10px 16px 16px 16px;
}

.tr-question {
  padding: 8px;
}

.trm-answer {
  margin-top: 15px;
  border: 1px solid var(--extralight);
  padding: 4px;
  margin-bottom: 15px;
  margin-left: 50px;
  margin-right: 50px;
}

.tr-report {
  border-radius: 6px;
  margin: 6px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 6px 1px rgb(147 145 145);
  padding-bottom: 2px;
}



.tr-head {
  background-color: rgb(196 196 196);
  padding: 10px;
  color: black;
  border-radius: 6px;
}

.tr-note {
  padding: 10px;
}


.tr-block {
  display: block;
}

.tr-ans {
  border: 1px solid #d5d5d5;
  margin: 10px;
  border-radius: 6px;
  padding: 1px;
  margin-bottom: 10px;
}


.select_box {
  width: 100% !important;
}


.hr-select {
  margin-bottom: 200px;
}

.tr-print {
  display: grid;
  place-items: center;
}

.tr-print-btn {
  text-align: center;
  padding: "5px";
  border: "1px solid black";
  margin: "5px";
}

.tr-print-report {
  position: absolute;
  z-index: 9999;
  min-width: 100%;
  top: 30px;
  left: 0;
  background: white;
  min-height: 100vh;
}

.bgwhite {
  background-color: #ffffff !important;
  color: #000 !important;
}

.kd-cursor {
  cursor: pointer;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.class-hover {
  position: absolute;
  left: 15%;
  max-width: 1000px;
  z-index: 99;
}



ul.list-unstyled.mb-5 li {
  list-style-type: none !important;
}



ul.list-unstyled.mb-5 {
  margin: 0px !important;
}




.btn-danger,
.btn-danger:hover,
.btn-info,
.btn-info:hover,
.btn-outline-danger:hover,
.btn-outline-info:hover,
.btn-outline-success:hover,
.btn-outline-warning:hover,
.btn-success,
.btn-success:hover,
.btn-warning,
.btn-warning:hover {
  color: #fff;
}

.btn-group-lg>.btn,
.btn-group-sm>.btn,
.btn-lg {
  border-radius: 50px;
}


.kd_main_view {
  padding: 74px 10px;
  transition: margin-left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: 83px;
}

/*
@media only screen and (max-width: 1141px) {} */




.right-bar {
  background: #fff;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 23px 0px rgb(0 0 0 / 3%);
}

.list-unstyled {
  padding: 0 !important;
  margin: 0 auto;
}












.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 10055 !important;
}


/* dashboard */
.header-bar {
  padding: 10px;
  color: white;
  background: linear-gradient(0deg, rgb(125 6 60) 0%, rgb(238 8 113) 80%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.margin_none {
  margin: 0px 10px;
}

.heading_transparent {
  background-color: transparent !important;
  margin-bottom: 20px;
}



.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 600px) {
  .row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    align-items: stretch;
  }
}

.col-md-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0 20%;
  flex: 0 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
}


.dashboard-table {
  width: 100%;
}


.dashboard-table table {
  width: 95%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}





.dashboard-table table {
  width: 95%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.dashboard-table table tr td:first-child {
  color: #712879;
}

.dashboard-table table td {
  padding: 5px 10px 5px 0px;
}





.reward-div {
  width: 100%;
  background: linear-gradient(180deg, #ffc0cb 30%, #f5f5f5 50%);
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.user-reward-pic {
  width: 150px;
  height: 150px;
  background-size: cover;
  border-radius: 80px;
  margin: auto;
}

.reward-div h5 {
  margin-top: 15px;
  font-weight: 700;
  font-size: 1em;
  color: #7a0a79;
}

.reward-div p {
  font-weight: 700;
}

g.text-group {
  display: none;
}

.tr-per {
  display: flex;
  flex-flow: row-reverse;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .tr-per {
    display: flex;
    flex-flow: row-reverse;
    padding: 10px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1141px) {

  .col-md-3,
  .col-md-9,
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dashboard-table table td {
    padding: 5px 10px 5px 0px;
  }

  .bdr-grey {
    border: none;
  }
}

.q-list {
  padding: 3px 0px;
  display: flex;
}

.q-list .q-content {
  white-space: initial;
  padding-left: 5px;
}


/* Mui Changes */
.MuiRadio-colorSecondary.Mui-checked {
  color: var(--ratingcolor) !important;
}




#empreport th {
  padding: 8px;
  text-align: center;
}

#empreport th.tr-answer {
  padding: 0px;
}

#quaterly-okr {
  height: 305px !important;
}

#quaterly-okr th {
  padding: 8px;
  text-align: left;
}

/* version 3.0 css */
.dashboard {
  display: grid;
  height: 100%;
  grid-template-columns: 210px auto;
}

.brand-logo {
  height: auto;
  display: grid;
  place-items: center;
  padding:5px;
  margin: 5pt 0;

}

.main-menu {
  /* background-image: linear-gradient(90deg, #240130, #ffffff4a); */
  background-color: var(--darkpurple);
  color: var(--white);
  height: 100vh;
  position: sticky;
  top: 0px;
  bottom: 0px;
  cursor: pointer;
  overflow-x: auto;
}

.menu {
  height: 42px;
  font-size: 1.2em;
  display: grid;
  grid-template-columns: 13px auto;
  gap: 16px;
  padding-left: 30px;
  align-items: center;
}

.MuiAppBar-colorPrimary {
  background-color: var(--white) !important;
}

.main {
  display: grid;
  grid-template-rows: 65px auto;
}

.activeLink > div {
  background-color: #e9ebed !important;
  color: var(--black) !important;
}

.activeLink svg {
  color: var(--black);
}

.childLink .activeLink span, .childLink span {
  font-size: 14px !important;
  margin-left: 33px;
}



.p32 {
  padding: 32px;
}

.p4 {
  padding: 4px;
}

.p0 {
  padding: 0px !important;
}

.p16 {
  padding: 16px !important;
}

.pt16 {
  padding-top: 16px !important;
}

.p32 {
  padding: 32px !important;
}

.pl16 {
  padding-left: 16px;
}

.px8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.h-60 {
  height: 60vh;
}

.h-50 {
  height: 50vh;
}

.h-80 {
  height: 80vh;
}

.mb16 {
  margin-bottom: 16px;
}

.mb10 {
  margin-bottom: 10px;
}

.stepPadding {
  padding: 20px 0;
}

.mt16 {
  margin-top: 16px;
}

.grid {
  display: grid;
}

.grid-center {
  place-items: center;
}

.rg32 {
  row-gap: 32px;
}

.inline-table {
  display: inline-table;
}

.grid-container {
  display: grid;
  grid-template-rows: 220px auto auto;
  row-gap: 20px;
}

.profile-card {
  display: grid;
  grid-template-rows: 158px auto;

}

.profile-image {
  background-color: #16171a;
  background-image: url('../src/assets/Mask\ group\ \(1\).png');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-light {
  background-color: var(--white);
}

.bg-lightwhite {
  background-color: #ffffffb8;
}

.profile {
  position: absolute;
  top: 40px;
  left: 29px;
}

.name {
  position: absolute;
  color: black;
  top: 172px;
  left: 230px;
  /* border: 1px solid; */
  width: calc(100% - 229px);
  display: flex;
  justify-content: space-between;
}

.dashboard-flex {
  display: flex;
  gap: 16px;
}

.col-4 {
  flex: 33.33% 1;
  display: grid;
  row-gap: 16px;
  /* grid-template-rows: 62% 38%; */
}

.col-4 .keynote {
  overflow: scroll;
  max-height: 300px;
}

.col-8 {
  flex: 66.66%;
  display: grid;
  /* grid-template-rows: 50% 50%; */
  row-gap: 20px;
}

.col-8 div {
  /* padding-top: 10px;
  padding-bottom: 10px; */
  background-color: var(--white);
}


.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.score-chart {
  display: grid;
  grid-template-columns: auto 70%;
  column-gap: 16px;
}

.employee-details {
  background-color: var(--white);
}

.keynote,.quaterly-okr {
  background-color: var(--white);
}

.reward-container,.crp-container {
  background-color: var(--white);
  /* margin-top: 16px; */
  /* padding-bottom: 16px; */
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}


.event-list {
  padding: 10px 30px;
  display: flex ;
  flex-direction: column;
  gap: 15px;
  height: 300px;
  overflow: scroll;
}

.event-card {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 1fr;
}

.event-card img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid white;
box-shadow: 0px 6px 5px #dfdede;
}

.event-card span {
  font-size: 15px;
}

.event-name {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: start;
  margin: 0 15px;
}

.event-details {
  display: flex;
  flex-direction: column;
}

.event-date {
  font-size: 13px !important;
  color:#3a3939
}

.event-message {
  font-size: 11px !important;
  width: 130px;
  color: #e91e63;
}



.card {
  width: auto;
  position: relative;
  border: 1px solid rgb(194, 194, 194);
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}

.reward-title-card {
  width: auto;
  position: relative;
  border: 1px solid var(--lightwhite);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}

.reward-title-card:hover {
  border: 1px solid #B87FFE;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}



.cardImg {
  height: 200px;
  width: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.cardDesc {
  text-align: center;
}

.card-container {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fill, 326px);
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.br4 {
  border-radius: 4px;
}
.br4i {
  border-radius: 4px 4px 0 0 !important;
  border-bottom: 1px solid rgb(191, 191, 191) !important;
}

.overflow {
  overflow: auto;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.w-100 {
  width: 100%;
}

.semibold {
  font-weight: 500;
}

.bold {
  font-weight: 700 !important;
}

.fs11 {
  font-size: 1.1em;
}

.fs12 {
  font-size: 1.2em;
}

.fs13 {
  font-size: 1.3em;
}

.fs14 {
  font-size: 1.4em;
}

.fs15 {
  font-size: 1.5em;
}

.fs20 {
  font-size: 2em;
}

.fs24 {
  font-size: 2.4rem;
}

.m0 {
  margin: 0px;
}

.no-border {
  border: none !important;
}

.manager-answer {
  padding: 16px;
  border: 1px solid var(--lightwhite);
  position: relative;
  margin: 16px;
}

.manager-answer:hover {
  border: 1px solid var(--outlinecolor);
}

.manager-answer-title {
  position: absolute;
  background-color: var(--mat);
  top: -16px;
  left: 16px;
  padding: 6px;
}

.ck-manager-answer-title {
  position: absolute;
  background-color: var(--mat);
  top: 2px;
  left: 24px;
  padding: 6px;
}



.reward-grid {
  display: grid;
  row-gap: 16px;
}

.icon-head {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.reward-container-grid {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, 326px);
  gap: 16px !important;
  justify-content: center;

}

.kd-help {
  color: var(--helpiconcolor);
}

.employee-report-grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 16px;
  max-height: 260px;
  overflow: auto;
  border-radius: 4px;

}


.crp-rating-chart {
  grid-template-columns: 1fr 1fr !important;
  padding:10px 20px;
  gap:50px;
  max-height:310px !important
}

.main-employee-report-grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 320px;
  gap: 16px;
  padding:10px
}

.score-flex {
  display: flex;
  column-gap: 16px;
}

.sb {
  justify-content: space-between;
}

.end {
  justify-content: end;
}

.reward-title-icon {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.reward-title-icon img {
  height: 22px;
  width: 22px;
}

.btn-purple {
  background-color: var(--ratingcolor) !important;
  color: var(--white) !important;
}

.vertical {
  vertical-align: middle;
}

.add-reward-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center
}

.cg16 {
  column-gap: 16px;
}

/* Login page */

.login-main {
  /* margin: 0 !important;
  min-height: 100vh; */
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.login-bg {
  background-image: url("./assets/Login\ Screen.svg");
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-content {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:20px;
max-width: 600px;
text-align: center;
margin-right: 80px;
}

.login-gmail-button {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 6rem 0.6rem;
  background-color: var(--ratingcolor);
  color: var(--white);
  border: 0px;

}

.login-gmail-button:hover {
  background-color: var(--hovercolor);
}

#button:hover {
  background-color: var(--hovercolor) !important;
  color: var(--white)
}

.css-m1gykc.Mui-selected {
  color: #fff !important;
  background-color: var(--hovercolor) !important;
}

/* .Mui-selected {
  color: rgb(255, 255, 255) !important;
  background-color: var(--hovercolor) !important;
} */

.form-container {
  margin: 0px 10%;
}

.form-controller table{
  border:1px solid rgb(205, 205, 205) !important
}

.form-controller table td{
  border:0 !important
}

.version {
  width: 100%;
  padding: 16px;
}

.flex-column {
  flex-direction: column;
}

.alert {
  position: absolute;
  top: 20%;
  left: 10%;
  width: fit-content;
}

/* #mf */
/* label {
  top: -6px !important;
} */

#borderless {
  border: 1px solid var(--lightwhite);
  padding: 32px;
  border-collapse: collapse !important;
  border-radius: 4px;
}

#borderless:hover {
  border: 1px solid var(--outlinecolor);
}

.react-confirm-alert-overlay {
  background: rgb(0 0 0 / 65%) !important;
  z-index: 1111 !important;
}

.custom-ui {
  padding: 40px 40px 0px 40px;
  width: 482px;
  display: grid;
  place-items: center;
  background: rgb(255, 255, 255);

}

.custom-ui button {
  margin-left: 4px;
  margin-right: 4px;
}

.custom-ui button:hover {
  background-color: var(--hovercolor);
  color: var(--white);
}

summary::marker{
  font-size: 0.8rem;
  padding-right: 4px;
}

#lightbox {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.92);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

}

#lightbox img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
  border: 1px solid #707070;
  background: #ffffff1f;
}


.lightbox-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
  border: 1px solid #707070;
  background: #ffffff1f;
}

.stepperCount {
  margin-bottom:1px;
  background-color:#240130;
  width:fit-content;
  padding:.2rem .5rem;
  color:#FFFFFF;
  border-radius:100%;
}

.stepperCountInactive {
  margin-bottom:1px;
  border: 1px solid #ADADAD;
  width:fit-content;
  padding:.2rem .5rem;
  color:rgba(0, 0, 0, 0.6);
  border-radius:100%;
}


span.MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel.css-1evpeub-MuiStepLabel-root {
  flex-direction: column-reverse !important;
}

hr:nth-last-child(1){
  display:none;
}

.grayed-out-bg {
  background-color: #888888 !important;
}

.grayed-out-text {
  color:#868686 !important;
}

.MuiMenu-list li.MuiMenuItem-root {
  display: block;
  padding: 4px;
}
